import React, { useState, useEffect } from 'react';
import './MealPlanner.css';

const MealPlanner = () => {
  const calculateTimeLeft = () => {
    const difference = +new Date('2024-10-01') - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="meal-planner">
      <h1>Meal Planner Coming Soon</h1>
      <div className="countdown">
        {timeLeft.days !== undefined ? (
          <>
            <span>{timeLeft.days} days </span>
            <span>{timeLeft.hours} hours </span>
            <span>{timeLeft.minutes} minutes </span>
            <span>{timeLeft.seconds} seconds</span>
          </>
        ) : (
          <span>Time's up!</span>
        )}
      </div>
    </div>
  );
};

export default MealPlanner;