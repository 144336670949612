import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import TermsOfService from './Legal/TermsOfService';
import AboutUs from './Legal/AboutUs';
import MealPrep from './MealPrep/MealPrep';
import GymSoftware from './GymSoftware/GymSoftware';
import Home from './TrimFitOnline/Home';
import ContactUs from './Legal/ContactUs';
import PrivacyPolicy from './Legal/PrivacyPolicy';
import Blog from './TrimFitOnline/Blog';
import MealPlanner from './TrimFitOnline/MealPlanner';
import Recipes from './TrimFitOnline/Recipes';
import './App.css';

function App() {
  const [selectedMainSection, setSelectedMainSection] = useState('TrimFitOnline');
  const [showNav, setShowNav] = useState(true);
  const navigate = useNavigate();

  const formatPath = (section) => {
    return section.toLowerCase().replace(/ /g, '-');
  };

  const handleMainNavClick = (section) => {
    console.log('Navigating to:', section); // Debugging line
    if (section === 'TrimFitOnline') {
      setSelectedMainSection('TrimFitOnline');
      setShowNav(true);
      navigate('/');
    } else if (['Home', 'Blog', 'Meal Planner', 'Recipes'].includes(section)) {
      setSelectedMainSection('TrimFitOnline');
      setShowNav(true);
      if (section === 'Home') {
        navigate('/');
      } else {
        navigate(`/${formatPath(section)}`);
      }
    } else if (section === 'Legal') {
      setSelectedMainSection('Legal');
      setShowNav(true);
      navigate('/legal/about-us');
    } else if (['About Us', 'Contact Us', 'Privacy Policy', 'Terms Of Service'].includes(section)) {
      setSelectedMainSection('Legal');
      setShowNav(true);
      navigate(`/legal/${formatPath(section)}`);
    } else {
      setSelectedMainSection('');
      setShowNav(false);
      navigate(`/${formatPath(section)}`);
    }
  };

  const renderSubNavItems = () => {
    if (selectedMainSection === 'TrimFitOnline') {
      return ['Home', 'Blog', 'Meal Planner', 'Recipes'];
    } else if (selectedMainSection === 'Legal') {
      return ['About Us', 'Contact Us', 'Privacy Policy', 'Terms Of Service'];
    }
    return [];
  };

  return (
    <div className="App">
      <header className="App-header">
        <nav className="navbar">
          <button onClick={() => handleMainNavClick('TrimFitOnline')}>TrimFitOnline</button>
          <button onClick={() => handleMainNavClick('Meal Prep')}>Meal Prep</button>
          <button onClick={() => handleMainNavClick('Gym Software')}>Gym Software</button>
          <button onClick={() => handleMainNavClick('Legal')}>Legal</button>
        </nav>
        {showNav && (
          <div className="sub-nav">
            {renderSubNavItems().map((item) => (
              <button key={item} onClick={() => handleMainNavClick(item)}>
                {item}
              </button>
            ))}
          </div>
        )}
      </header>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/meal-prep" element={<MealPrep />} />
          <Route path="/gym-software" element={<GymSoftware />} />
          <Route path="/legal/about-us" element={<AboutUs />} />
          <Route path="/legal/contact-us" element={<ContactUs />} />
          <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/legal/terms-of-service" element={<TermsOfService />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/meal-planner" element={<MealPlanner />} />
          <Route path="/recipes" element={<Recipes />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;











// Pre url one
/*




import React, { useState } from 'react';
import TermsOfService from './Legal/TermsOfService';
import AboutUs from './Legal/AboutUs';
import MealPrep from './MealPrep/MealPrep';
import GymSoftware from './GymSoftware/GymSoftware';
import Home from './TrimFitOnline/Home';
import ContactUs from './Legal/ContactUs';
import PrivacyPolicy from './Legal/PrivacyPolicy';
import Blog from './TrimFitOnline/Blog';
import MealPlanner from './TrimFitOnline/MealPlanner';
import Recipes from './TrimFitOnline/Recipes';
import './App.css';

function App() {
  const [currentSection, setCurrentSection] = useState('Home');
  const [selectedMainSection, setSelectedMainSection] = useState('TrimFitOnline');
  const [showNav, setShowNav] = useState(true);

  const handleMainNavClick = (section) => {
    if (section === 'TrimFitOnline') {
      setCurrentSection('Home');
      setSelectedMainSection('TrimFitOnline');
      setShowNav(true);
    } else if (['Home', 'Blog', 'Meal Planner', 'Recipes'].includes(section)) {
      setCurrentSection(section);
      setSelectedMainSection('TrimFitOnline');
      setShowNav(true);
    } else if (section === 'Legal') {
      setCurrentSection('About Us');
      setSelectedMainSection('Legal');
      setShowNav(true);
    } else if (['About Us', 'Contact Us', 'Privacy Policy', 'Terms Of Service'].includes(section)) {
      setCurrentSection(section);
      setSelectedMainSection('Legal');
      setShowNav(true);
    } else {
      setCurrentSection(section);
      setSelectedMainSection('');
      setShowNav(false);
    }
  };

  const renderSection = () => {
    switch (currentSection) {
      case 'Terms Of Service':
        return <TermsOfService />;
      case 'About Us':
        return <AboutUs />;
      case 'Privacy Policy':
        return <PrivacyPolicy />;
      case 'Meal Prep':
        return <MealPrep />;
      case 'Gym Software':
        return <GymSoftware />;
      case 'Contact Us':
        return <ContactUs />;
      case 'Blog':
        return <Blog />;
      case 'Meal Planner':
        return <MealPlanner />;
      case 'Recipes':
        return <Recipes />;
      default:
        return <Home />;
    }
  };

  const renderSubNavItems = () => {
    if (selectedMainSection === 'TrimFitOnline') {
      return ['Home', 'Blog', 'Meal Planner', 'Recipes'];
    } else if (selectedMainSection === 'Legal') {
      return ['About Us', 'Contact Us', 'Privacy Policy', 'Terms Of Service'];
    }
    return [];
  };

  return (
    <div className="App">
      <header className="App-header">
        <nav className="navbar">
          <button onClick={() => handleMainNavClick('TrimFitOnline')}>TrimFitOnline</button>
          <button onClick={() => handleMainNavClick('Meal Prep')}>Meal Prep</button>
          <button onClick={() => handleMainNavClick('Gym Software')}>Gym Software</button>
          <button onClick={() => handleMainNavClick('Legal')}>Legal</button>
        </nav>
        {showNav && (
          <div className="sub-nav">
            {renderSubNavItems().map((item) => (
              <button key={item} onClick={() => handleMainNavClick(item)}>
                {item}
              </button>
            ))}
          </div>
        )}
      </header>
      <main>
        {renderSection()}
      </main>
    </div>
  );
}

export default App;




*/


/*



import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import TermsOfService from './Legal/TermsOfService';
import AboutUs from './Legal/AboutUs';
import MealPrep from './MealPrep/MealPrep';
import GymSoftware from './GymSoftware/GymSoftware';
import Home from './TrimFitOnline/Home';
import ContactUs from './Legal/ContactUs';
import PrivacyPolicy from './Legal/PrivacyPolicy';
import Blog from './TrimFitOnline/Blog';
import MealPlanner from './TrimFitOnline/MealPlanner';
import Recipes from './TrimFitOnline/Recipes';
import './App.css';

function App() {
  const [selectedMainSection, setSelectedMainSection] = useState('TrimFitOnline');
  const [showNav, setShowNav] = useState(true);
  const navigate = useNavigate();

  const handleMainNavClick = (section) => {
    if (section === 'TrimFitOnline') {
      setSelectedMainSection('TrimFitOnline');
      setShowNav(true);
      navigate('/');
    } else if (['Home', 'Blog', 'Meal Planner', 'Recipes'].includes(section)) {
      setSelectedMainSection('TrimFitOnline');
      setShowNav(true);
      navigate(`/${section.toLowerCase().replace(' ', '-')}`);
    } else if (section === 'Legal') {
      setSelectedMainSection('Legal');
      setShowNav(true);
      navigate('/legal/about-us');
    } else if (['About Us', 'Contact Us', 'Privacy Policy', 'Terms Of Service'].includes(section)) {
      setSelectedMainSection('Legal');
      setShowNav(true);
      navigate(`/legal/${section.toLowerCase().replace(' ', '-')}`);
    } else {
      setSelectedMainSection('');
      setShowNav(false);
      navigate(`/${section.toLowerCase().replace(' ', '-')}`);
    }
  };

  const renderSubNavItems = () => {
    if (selectedMainSection === 'TrimFitOnline') {
      return ['Home', 'Blog', 'Meal Planner', 'Recipes'];
    } else if (selectedMainSection === 'Legal') {
      return ['About Us', 'Contact Us', 'Privacy Policy', 'Terms Of Service'];
    }
    return [];
  };

  return (
    <div className="App">
      <header className="App-header">
        <nav className="navbar">
          <button onClick={() => handleMainNavClick('TrimFitOnline')}>TrimFitOnline</button>
          <button onClick={() => handleMainNavClick('Meal Prep')}>Meal Prep</button>
          <button onClick={() => handleMainNavClick('Gym Software')}>Gym Software</button>
          <button onClick={() => handleMainNavClick('Legal')}>Legal</button>
        </nav>
        {showNav && (
          <div className="sub-nav">
            {renderSubNavItems().map((item) => (
              <button key={item} onClick={() => handleMainNavClick(item)}>
                {item}
              </button>
            ))}
          </div>
        )}
      </header>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/meal-prep" element={<MealPrep />} />
          <Route path="/gym-software" element={<GymSoftware />} />
          <Route path="/legal/about-us" element={<AboutUs />} />
          <Route path="/legal/contact-us" element={<ContactUs />} />
          <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/legal/terms-of-service" element={<TermsOfService />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/meal-planner" element={<MealPlanner />} />
          <Route path="/recipes" element={<Recipes />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;


*/
