import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <div className="contact-us">
      <h1>Contact Us</h1>
      <p>Email: <a href="mailto:Bowens@xyzsoftwarellc.com">Bowens@xyzsoftwarellc.com</a></p>
    </div>
  );
};

export default ContactUs;