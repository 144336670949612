import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us">
      <h6>Owned by XYZ Software LLC</h6>
      <h1>TrimFit</h1>
      <h2>Helping Create the Best You </h2>

      <section>
        <h3>Our Mission</h3>
        <p>At TrimFit, our mission is to help your fitness journey. We are dedicated to helping bring you closer to your fitness goals.</p>
      </section>

      <section>
        <h3>Our Story</h3>
        <p>Founded in 2024, TrimFit began with a vision to help people loose weight for free. Our goal is to impact 1 million people's weight loss journey </p>
      </section>

      <section>
        <h3>What We Do</h3>
        <p>We specialize in fitness software. Our unique approach to caring about customers sets us apart from the competition.</p>
      </section>

    </div>
  );
};

export default AboutUs;