import React from 'react';
import './TermsOfService.css';

const TermsOfService = () => {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>
      <p><strong>Effective Date: August 15th, 2024</strong></p>
      
      <h2>1. Introduction</h2>
      <p>Welcome to XYZ Software LLC's TrimFit division. These Terms of Service govern your use of our website and mobile app(s) and services. By accessing or using our website and services, you agree to these Terms. If you do not agree, please do not use our website or services.</p>
      
      <h2>2. Use of Our Services</h2>
      <h3>Eligibility</h3>
      <p>You must be at least 18 years old or the age of majority in your jurisdiction to use our services. By using our services, you represent and warrant that you meet these requirements.</p>
      
      <h3>Account Registration</h3>
      <p>To access certain features, you may need to create an account. You agree to provide accurate, complete, and up-to-date information and to update it as necessary. You are responsible for maintaining the confidentiality of your account and for all activities under your account.</p>
      
      <h2>3. User Responsibilities</h2>
      <h3>Prohibited Conduct</h3>
      <p>You agree not to engage in any of the following prohibited conduct:</p>
      <ul>
        <li>Violating any applicable laws or regulations</li>
        <li>Infringing on the intellectual property rights of others</li>
        <li>Harassing, abusing, or threatening others</li>
        <li>Distributing viruses or other harmful code</li>
        <li>Attempting to gain unauthorized access to our systems or accounts</li>
        <li>Using our services for fraudulent or illegal purposes</li>
      </ul>
      
      <h2>4. Intellectual Property</h2>
      <h3>Ownership</h3>
      <p>All content, trademarks, and other intellectual property on our website and services are owned by [Your Company Name] or its licensors. You may not use, reproduce, or distribute any of this content without our prior written consent.</p>
      
      <h2>5. Disclaimers and Limitation of Liability</h2>
      <h3>Disclaimer of Warranties</h3>
      <p>Our website and services are provided "as is" and "as available" without any warranties of any kind, either express or implied. We do not warrant that our services will be uninterrupted, error-free, or free from harmful components.</p>
      
      <h3>Limitation of Liability</h3>
      <p>To the maximum extent permitted by law, XYZ Software LLC shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from or related to your use of our website or services.</p>
      
      <h2>6. Indemnification</h2>
      <p>You agree to indemnify, defend, and hold harmless XYZ Software LLC, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses arising out of or related to your use of our website or services or your violation of these Terms.</p>
      
      <h2>7. Termination</h2>
      <p>We reserve the right to terminate or suspend your account and access to our services at our sole discretion, without prior notice, for any reason, including if we believe you have violated these Terms.</p>
      
      <h2>8. Governing Law</h2>
      <p>These Terms shall be governed by and construed in accordance with the laws of Idaho, without regard to its conflict of law principles. Any disputes arising under these Terms shall be resolved in the courts located in Idaho.</p>
      
      <h2>9. Changes to These Terms</h2>
      <p>We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our website and updating the effective date at the top. Your continued use of our website or services after any such changes constitutes your acceptance of the new Terms.</p>
    </div>
  );
};

export default TermsOfService;
