import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Effective Date: August 15th, 2024</p>

      <h2>1. Introduction</h2>
      <p>Welcome to XYZ Software LLC's TrimFit division. We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and mobile app or use our services. Please read this policy carefully.</p>

      <h2>2. Information We Collect</h2>
      <h3>Personal Information</h3>
      <p>We may collect personally identifiable information, such as your name, email address, phone number, and any other information you provide directly to us.</p>

      <h3>Non-Personal Information</h3>
      <p>We may collect non-personally identifiable information, such as browser type, IP address, and usage data, to help us improve our services.</p>

      <h2>3. How We Use Your Information</h2>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Provide, operate, and maintain our website and services</li>
        <li>Improve, personalize, and expand our website and services</li>
        <li>Understand and analyze how you use our website and services</li>
        <li>Develop new products, services, features, and functionality</li>
        <li>Communicate with you, including responding to your inquiries and sending you updates</li>
        <li>Process transactions and send you related information</li>
        <li>Protect against and prevent fraud and abuse</li>
        <li>Other marketing purposes</li>
      </ul>

      <h2>4. How We Share Your Information</h2>
      <p>We may share your information in the following circumstances:</p>
      <ul>
        <li><strong>With Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, and hosting.</li>
        <li><strong>For Legal Reasons:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a subpoena or court order).</li>
        <li><strong>Business Transfers:</strong> In connection with a merger, acquisition, or any form of asset sale, we may transfer your information to the acquiring entity.</li>
      </ul>

      <h2>5. Cookies and Tracking Technologies</h2>
      <p>We use cookies and similar tracking technologies to track the activity on our website and store certain information. Cookies help us improve your experience and analyze how our website is used. You can set your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our website.</p>

      <h2>6. Data Security</h2>
      <p>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or method of electronic storage is 100% secure, so we cannot guarantee absolute security.</p>

      <h2>7. Your Choices and Rights</h2>
      <p>Depending on your location, you may have the following rights:</p>
      <ul>
        <li><strong>Access and Correction:</strong> You can request access to or correction of your personal information.</li>
        <li><strong>Deletion:</strong> You can request that we delete your personal information.</li>
        <li><strong>Opt-Out:</strong> You can opt-out of receiving promotional communications from us.</li>
      </ul>
      <p>To exercise these rights, please contact us at [contact email address].</p>

      <h2>8. Third-Party Links</h2>
      <p>Our website may contain links to other websites that are not operated by us. We are not responsible for the privacy practices or content of third-party sites. We encourage you to review the privacy policies of any third-party sites you visit.</p>

      <h2>9. Children's Privacy</h2>
      <p>Our website and services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information.</p>

      <h2>10. Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the effective date at the top. You are advised to review this Privacy Policy periodically for any changes.</p>
    </div>
  );
};

export default PrivacyPolicy;