import React from 'react';
import './Home.css';

const Home = () => {
  return (
    <div className="home">
      <div className="section red-section">
        <h1>App On Phone Section(Model after my fitness Pal)</h1>
        <p>Download now button</p>
      </div>
      <div className="section white-section">
        <h1>What the App does</h1>
        <p>This is the second section.</p>
      </div>
      <div className="section blue-section">
        <h1>Frequently asked questions</h1>
        <p>Slide down answers</p>
      </div>
    </div>
  );
};

export default Home;